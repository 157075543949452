import React from "react";
import { useInView } from "react-intersection-observer";
import { motion } from "framer-motion";
import "./Process.css";
import frame1 from "../GrafinImg/frame1.jpg";
import frame2 from "../GrafinImg/frame2.png";
import frame3 from "../GrafinImg/process.jpg";
import frame4 from "../GrafinImg/frame4.png";
import frame5 from "../GrafinImg/frame5.jpg";

const Process = () => {
  const [ref1, inView1] = useInView({ triggerOnce: true });
  const [ref2, inView2] = useInView({ triggerOnce: true });
  const [ref3, inView3] = useInView({ triggerOnce: true });

  return (
    <section>
      <div className="containers">
        <div className="process-container">
          <motion.h1
            className="process-heading"
            initial={{ opacity: 0, y: 50 }}
            animate={inView1 ? { opacity: 1, y: 0 } : {}}
            transition={{ duration: 0.8 }}
            ref={ref1}
          >
            Explore Our Process
          </motion.h1>
        </div>

        <div className="rows">
          <motion.div
            className="colums-1"
            initial={{ opacity: 0, x: -100 }}
            animate={inView1 ? { opacity: 1, x: 0 } : {}}
            transition={{ duration: 0.8, delay: 0.4 }}
            ref={ref1}
          >
            <div className="process-image">
              <img
                className="colum1-image"
                src={frame1}
                alt="Discovery & Planning"
              />
            </div>
            <div className="overlay1">
              <h2 className="overlay-heading1">Discovery & Planning</h2>
              <h2 className="overlay-heading2">
                Understand client needs, target <br />
                audience, and unique requirements.
              </h2>
            </div>
          </motion.div>

          <motion.div
            className="colums-2"
            initial={{ opacity: 0, x: 100 }}
            animate={inView1 ? { opacity: 1, x: 0 } : {}}
            transition={{ duration: 0.8, delay: 0.4 }}
            ref={ref1}
          >
            <div className="process-image">
              <img
                className="colum2-image"
                src={frame2}
                alt="Design & Prototyping"
              />
            </div>
            <div className="overlay2">
              <h2 className="overlay-heading21">Design & Prototyping</h2>
              <h2 className="overlay-heading22">
                Create a visually appealing
                <br />
                and intuitive layout.
              </h2>
            </div>
          </motion.div>
        </div>

        <div className="rows">
          <motion.div
            className="colums-11"
            initial={{ opacity: 0, y: 100 }}
            animate={inView2 ? { opacity: 1, y: 0 } : {}}
            transition={{ duration: 0.8, delay: 0.8 }}
            ref={ref2}
          >
            <div className="parent-img1">
              <img className="images1" src={frame3} alt="Testing" />
              <div className="inner-text">
                <h2 className="inner1">Development</h2>
                <h2 className="inner2">
                Build the website with efficient,<br/> 
                clean code.
                </h2>
              </div>
            </div>
            <div className="parent-img2">
              <img className="images1" src={frame4} alt="Testing" />
              <div className="inner-text">
                <h2 className="inner1">Testing & Optimization</h2>
                <h2 className="inner2">
                  Ensure the website is functional,
                  <br />
                  fast, and bug-free.
                </h2>
              </div>
            </div>
          </motion.div>

          <motion.div
            className="colums-12"
            initial={{ opacity: 0, scale: 0.8 }}
            animate={inView3 ? { opacity: 1, scale: 1 } : {}}
            transition={{ duration: 0.8, delay: 0.8 }}
            ref={ref3}
          >
            <div className="process-image">
              <img className="images-1" src={frame5} alt="Launch" />
            </div>
            <div className="inner-content">
              <h2 className="inner-div1">Launch & Post-Launch Help</h2>
              <h2 className="inner-div2">
                Successfully deploy the site
                <br />
                and provide ongoing support.
              </h2>
            </div>
          </motion.div>
        </div>
      </div>
    </section>
  );
};

export default Process;
