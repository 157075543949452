import React from 'react'
import "./Products.css";

const Products = () => {
  return (
    <div>
        <section>
        <div className="portfolio-container">
          <div className='products-container'>
          <h1 className="container-heading">Our Products</h1>
          </div>
          <div className="row">
            {/* First Container */}
            <div className="single-image-container">
              <div className="add-content1">
                <h1 className="project-heading1">GRAFIN</h1>
                <h3 className="project-heading2">E-Commerce </h3>
              </div>
            </div>

            {/* Second Container */}
            <div className="grid-container">
              <div className="grid-item1">
                <div className="add-content2">
                  <h1 className="project-heading3">JINFLO</h1>
                  <h3 className="project-heading4">Internet of thinks</h3>
                </div>
              </div>
              <div className="grid-item2">
                <div className="add-content3">
                  <h1 className="project-heading5">LERMAX</h1>
                  <h3 className="project-heading6">LMS</h3>
                </div>
              </div>
              <div className="grid-item3">
                <div className="add-content4">
                  <h1 className="project-heading7">EDUCLAR</h1>
                  <h3 className="project-heading8">Smart School</h3>
                </div>
              </div>
              <div className="grid-item4">
                <div className="add-content5">
                  <h1 className="project-heading9">SUDO</h1>
                  <h3 className="project-heading10">Food Delivery</h3>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

export default Products;