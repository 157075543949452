import React from 'react';
import "./Approach.css";
import approach1 from "..//GrafinImg/approach1.png";
import approach2 from "..//GrafinImg/approach2.png";
import approach3 from "..//GrafinImg/approach3.png";
import approach4 from "..//GrafinImg/approach4.png";

const Approach = () => {
  return (
    <div>
        <section>
        <div className="approach-containermain">
          <div className="approach-container">
            <h1 className="approach-heading">Our Approach</h1>
          </div>

          <div className="hover-container">
            <div className="image-wrapper">
              <img src={approach1} alt="Approach 1" className="hover-image" />
              <div className="overlay">
                <h2 className="overlay-header">Consulting</h2>
              </div>
              <div className="hover-content1">
                <p>
                  "We provide strategic
                  <br /> consulting to drive innovation,streamlin processes, and
                  <br /> empower businesses to <br />
                  each their full 
                  <br />
                  potential."
                </p>
              </div>
            </div>
            <div className="image-wrapper">
              <img src={approach2} alt="Approach 2" className="hover-image" />
              <div className="overlay">
                <h2 className="overlay-header">Solution</h2>
              </div>
              <div className="hover-content2">
                <p>
                  "We provide expert consulting solutions at Grafin, helping
                  businesses navigate complex IT challenges and achieve
                  digital excellence."
                </p>
              </div>
            </div>
            <div className="image-wrapper">
              <img src={approach3} alt="Approach 3" className="hover-image" />
              <div className="overlay">
                <h2 className="overlay-header">Support</h2>
              </div>
              <div className="hover-content3">
                <p>
                  "We provide dedicated support at Grafin ensuring seamless IT
                  operations and empowering your business to thrive."
                </p>
              </div>
            </div>
            <div className="image-wrapper">
              <img src={approach4} alt="Approach 4" className="hover-image" />
              <div className="overlay">
                <h2 className="overlay-header">Efficiency Boost</h2>
              </div>
              <div className="hover-content4">
                <p>
                  "We provide solutions designed to boost efficiency,
                  streamlining processesand maximizing productivity
                  forsustained growth."
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

    </div>
  )
}

export default Approach;