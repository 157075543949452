import React from 'react'
import "./Video.css";
import video from "../GrafinImg/grafinvideo.mp4";

const Video = () => {
  return (
    <div>
        <section>
        <div className="container-video">
          <div className="video">
            <video autoPlay loop muted>
              <source src={video} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </div>

          <div className="video-text">
            <h1 className="video-fond1">
              Where Vision
              <br />
              Meet Innovation
            </h1>
          </div>
          <div className="video-para">
            <p className="para">
              Our expertise turns ideas into
              <br />
              transformative technology for
              <br />a smarter future."
            </p>
          </div>
        </div>
      </section>
    </div>
  )
}

export default Video;