import Nav from "./Components/Navbar/Nav";

import Process from "./Components/Homes/Process";
import Submit from "./Components/Homes/Submit"
import Footer from "./Components/Footers/Footer"
import Home from "./Components/Home";


function App() {
  return (
    <div className="App">
      <Nav />
      <Home/>
      <Submit/>
      <Footer/>
      
      
    </div>
  );
}

export default App;
