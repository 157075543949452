import React from 'react';
import "./DrawlineAnimation.css";

const DrawlineAnimation = () => {
  return (
    <div>
        <section className="svg-section">
        <div className="svg-container">
          <svg
            className="svg-box"
            width="410"
            height="440"
            viewBox="0 0 377 384"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              className="draw"
              d="M-0.5 3.75836C-0.5 3.75836 186.599 -2.44793 198.5 3.75833C215 12.3628 197.747 55.3973 209.434 66.4747C221.121 77.5521 298.552 66.415 309 74.5C314.815 79 308.815 97.5521 314.5 106.5C320.185 115.448 376.5 112 376.5 112"
              stroke="url(#paint0_linear_1117_484)"
              stroke-width="2"
            />
            <path
              className="draw"
              d="M0 382.323C0 382.323 191.099 381.706 203 375.5C214.901 369.294 197.747 330.684 209.434 319.606C221.121 308.529 311.81 323.923 317.5 312C322.58 301.357 315.605 290.625 324 281C328.361 276 375.5 276 375.5 276"
              stroke="url(#paint1_linear_1117_484)"
              stroke-width="2"
            />
            <path
              className="draw"
              d="M0.5 49.5C0.5 49.5 75.0332 44.9 85 49.5C104.5 58.5 84.5 105.5 99 113.5C113.5 121.5 211.176 109.548 215.5 121.5C219.36 132.17 213.224 142.868 219.5 150C225.776 157.132 344 154.5 344 154.5"
              stroke="url(#paint2_linear_1117_484)"
              stroke-width="2"
            />
            <path
              className="draw"
              d="M0 342.316C0 342.316 86.7873 344.722 95.8309 338.5C109.5 329.096 93.8309 282 105.5 275.5C117.922 268.581 222.14 280.17 226 269.5C229.86 258.83 222.5 239.5 235.5 239.5C253.5 239.5 365.5 239.5 365.5 239.5"
              stroke="url(#paint3_linear_1117_484)"
              stroke-width="2"
            />
            <path
              className="draw"
              d="M2 85.4999C2 85.4999 123 76.2034 133 85.5C143 94.7966 132.5 162.5 143 171C153.5 179.5 227 177.5 227 177.5H272H312.5"
              stroke="url(#paint4_linear_1117_484)"
              stroke-width="2"
            />
            <path
              className="draw"
              d="M0 293.221C0 293.221 50.0788 293.221 55.5 289.5C69.5 279.89 51.5 229 66 216C80.5 203 220.5 210 220.5 210H273H336"
              stroke="url(#paint5_linear_1117_484)"
              stroke-width="2"
            />
            <defs>
              <linearGradient
                id="paint0_linear_1117_484"
                x1="3.03089e-08"
                y1="49.3023"
                x2="569"
                y2="49.3023"
                gradientUnits="userSpaceOnUse"
              >
                <stop stop-color="#9F1972" />
                <stop offset="1" stop-color="#5315BE" />
              </linearGradient>
              <linearGradient
                id="paint1_linear_1117_484"
                x1="0"
                y1="336.779"
                x2="569"
                y2="336.779"
                gradientUnits="userSpaceOnUse"
              >
                <stop stop-color="#9F1972" />
                <stop offset="1" stop-color="#5315BE" />
              </linearGradient>
              <linearGradient
                id="paint2_linear_1117_484"
                x1="-1.4727e-08"
                y1="89.4236"
                x2="432.38"
                y2="89.4236"
                gradientUnits="userSpaceOnUse"
              >
                <stop stop-color="#9F1972" />
                <stop offset="1" stop-color="#5315BE" />
              </linearGradient>
              <linearGradient
                id="paint3_linear_1117_484"
                x1="0"
                y1="296.657"
                x2="432.38"
                y2="296.657"
                gradientUnits="userSpaceOnUse"
              >
                <stop stop-color="#9F1972" />
                <stop offset="1" stop-color="#5315BE" />
              </linearGradient>
              <linearGradient
                id="paint4_linear_1117_484"
                x1="2.12481e-07"
                y1="132.279"
                x2="435.273"
                y2="132.279"
                gradientUnits="userSpaceOnUse"
              >
                <stop stop-color="#9F1972" />
                <stop offset="1" stop-color="#5315BE" />
              </linearGradient>
              <linearGradient
                id="paint5_linear_1117_484"
                x1="0"
                y1="247.339"
                x2="435.481"
                y2="247.339"
                gradientUnits="userSpaceOnUse"
              >
                <stop stop-color="#9F1972" />
                <stop offset="1" stop-color="#5315BE" />
              </linearGradient>
            </defs>
          </svg>
          <div className="svg-box">
            <h2 className="svg-font">
              Creating Pathways For Seamles Collaboration Among Business
            </h2>
          </div>
          <svg
            className="svg-box"
            width="410"
            height="440"
            viewBox="0 0 378 384"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              className="draw"
              d="M378 3.75836C378 3.75836 190.901 -2.44793 179 3.75833C162.5 12.3628 179.753 55.3973 168.066 66.4747C156.379 77.5521 78.9481 66.415 68.5 74.5C62.6847 79 68.6847 97.5521 63 106.5C57.3153 115.448 1 112 1 112"
              stroke="url(#paint0_linear_1158_452)"
              stroke-width="2"
            />
            <path
              className="draw"
              d="M377.5 382.323C377.5 382.323 186.401 381.706 174.5 375.5C162.599 369.294 179.753 330.684 168.066 319.606C156.379 308.529 65.6905 323.923 60 312C54.9201 301.357 61.8949 290.625 53.5 281C49.1389 276 2 276 2 276"
              stroke="url(#paint1_linear_1158_452)"
              stroke-width="2"
            />
            <path
              className="draw"
              d="M377 49.5C377 49.5 302.467 44.9 292.5 49.5C273 58.5 293 105.5 278.5 113.5C264 121.5 166.324 109.548 162 121.5C158.14 132.17 164.276 142.868 158 150C151.724 157.132 33.5 154.5 33.5 154.5"
              stroke="url(#paint2_linear_1158_452)"
              stroke-width="2"
            />
            <path
              className="draw"
              d="M377.5 342.316C377.5 342.316 290.713 344.722 281.669 338.5C268 329.096 283.669 282 272 275.5C259.578 268.581 155.36 280.17 151.5 269.5C147.64 258.83 155 239.5 142 239.5C124 239.5 12 239.5 12 239.5"
              stroke="url(#paint3_linear_1158_452)"
              stroke-width="2"
            />
            <path
              className="draw"
              d="M375.5 85.4999C375.5 85.4999 254.5 76.2034 244.5 85.5C234.5 94.7966 245 162.5 234.5 171C224 179.5 150.5 177.5 150.5 177.5H105.5H65"
              stroke="url(#paint4_linear_1158_452)"
              stroke-width="2"
            />
            <path
              className="draw"
              d="M377.5 293.221C377.5 293.221 327.421 293.221 322 289.5C308 279.89 326 229 311.5 216C297 203 157 210 157 210H104.5H41.5"
              stroke="url(#paint5_linear_1158_452)"
              stroke-width="2"
            />
            <defs>
              <linearGradient
                id="paint0_linear_1158_452"
                x1="377.5"
                y1="49.3023"
                x2="-191.5"
                y2="49.3023"
                gradientUnits="userSpaceOnUse"
              >
                <stop stop-color="#9F1972" />
                <stop offset="1" stop-color="#5315BE" />
              </linearGradient>
              <linearGradient
                id="paint1_linear_1158_452"
                x1="377.5"
                y1="336.779"
                x2="-191.5"
                y2="336.779"
                gradientUnits="userSpaceOnUse"
              >
                <stop stop-color="#9F1972" />
                <stop offset="1" stop-color="#5315BE" />
              </linearGradient>
              <linearGradient
                id="paint2_linear_1158_452"
                x1="377.5"
                y1="89.4236"
                x2="-54.8799"
                y2="89.4236"
                gradientUnits="userSpaceOnUse"
              >
                <stop stop-color="#9F1972" />
                <stop offset="1" stop-color="#5315BE" />
              </linearGradient>
              <linearGradient
                id="paint3_linear_1158_452"
                x1="377.5"
                y1="296.657"
                x2="-54.8799"
                y2="296.657"
                gradientUnits="userSpaceOnUse"
              >
                <stop stop-color="#9F1972" />
                <stop offset="1" stop-color="#5315BE" />
              </linearGradient>
              <linearGradient
                id="paint4_linear_1158_452"
                x1="377.5"
                y1="132.279"
                x2="-57.7725"
                y2="132.279"
                gradientUnits="userSpaceOnUse"
              >
                <stop stop-color="#9F1972" />
                <stop offset="1" stop-color="#5315BE" />
              </linearGradient>
              <linearGradient
                id="paint5_linear_1158_452"
                x1="377.5"
                y1="247.339"
                x2="-57.9813"
                y2="247.339"
                gradientUnits="userSpaceOnUse"
              >
                <stop stop-color="#9F1972" />
                <stop offset="1" stop-color="#5315BE" />
              </linearGradient>
            </defs>
          </svg>
        </div>
      </section>  
    </div>
  )
}

export default DrawlineAnimation;