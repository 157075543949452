import React from 'react';
import "./Card.css";
import app1 from "..//GrafinImg/app1.png";
import app2 from "..//GrafinImg/app4.png";
import app3 from "..//GrafinImg/app3.png";
import app4 from "..//GrafinImg/app2.png";
import app5 from "..//GrafinImg/app5.png";
import app6 from "..//GrafinImg/app6.png";

const Card = () => {
  return (
    <div>
         <section>
        <div class="card-container">
          <div className="heading-container">
            <h1 className="card-heading">Our Service</h1>
          </div>
          <div className="main-row1">
            <div class="card1">
              <img src={app1} alt="Mobile App Development" />
              <h3>Mobile App Development</h3>
            </div>
            <div class="card1">
              <img src={app2} alt="Web Development" />
              <h3>Web Development</h3>
            </div>
            <div class="card1">
              <img src={app3} alt="Web Development" />
              <h3>Software Development</h3>
            </div>
          </div>
          <div className="main-row2">
            <div class="card1">
              <img src={app4} alt="Web Development" />
              <h3>Artificial Intelligence</h3>
            </div>
            <div class="card1">
              <img src={app5} alt="Web Development" />
              <h3>UI/UX Design System</h3>
            </div>
            <div class="card1">
              <img src={app6} alt="Web Development" />
              <h3>IoT Solutions</h3>
            </div>
          </div>
        </div>
      </section>

    </div>
  )
}

export default Card;