import React from "react";
import "./Nav.css";
import logo from "../GrafinImg/logo2.png";
import { useState } from "react";

const Nav = () => {
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <>
      {/* this is the first section */}
      <section>
        <div className="container-nav">
          <div className="navbar">
            <div className="nav-img">
              <img className="logo" src={logo} alt="Logo" />
            </div>
            <ul className={`nav-links ${menuOpen ? "active" : ""}`}>
              <li>
                <a className="home" href="#">
                  Home
                </a>
              </li>
              <li>
                <a href="#">What We Do</a>
              </li>
              <li>
                <a href="#">Who We Are</a>
              </li>
              <li>
                <a href="#">How To Reach</a>
              </li>
            </ul>
            {/* Menu toggle with conditional icons */}
            <div className="menu-toggle" onClick={toggleMenu}>
              {menuOpen ? (
                <span className="close-icon">&times;</span> // close icon
              ) : (
                <>
                  <span className="bar"></span>
                  <span className="bar"></span>
                  <span className="bar"></span>
                </>
              )}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Nav;
