import React from "react";
import "./Home.css";
import Video from "./Homes/Video";
import DrawlineAnimation from "./Homes/DrawlineAnimation";
import Card from "./Homes/Card";
import Process from "./Homes/Process";
import Approach from "./Homes/Approach";
import Products from "./Homes/Products";


const Home = () => {
  return (
    <div className="home">
      <Video/>
     <DrawlineAnimation/>
     <Card/>
     <Approach/>
     <Products/>
     <Process/>
    
    </div>
  );
};

export default Home;
